import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  Typography,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { differenceInWeeks, parseISO, subMonths, isAfter, subDays, formatISO } from 'date-fns';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '10px',
    margin: '10px auto 20px auto',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)'
  },
  error: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '56px',
    left: '0px',
    padding: '10px',
    width: '100vw',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black'
  },
  accordion: {
    flexDirection: 'column',
    padding: '8px 4px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: 'rgba(167,169,20,0.02)'
  },
  selected: {
    border: '1px solid gold',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  unselected: {
    border: '1px solid rgb(150,150,150)'
  },
  headingCont: {
    flexBasis: '100%',
    display: 'flex',
    maxWidth: '400px'
  },
  heading: {
    flexBasis: '55%',
    flexShrink: 0,
    textAlign: 'left'
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    flexBasis: '45%',
    flexShrink: 0,
    textAlign: 'right',
    paddingTop: '5px',
    fontSize: '1.1rem'
  },
  betaHeading: {
    color: theme.palette.text.secondary,
    flexBasis: '45%',
    flexShrink: 0,
    textAlign: 'right',
    paddingTop: '5px',
    fontSize: '1.0rem'
  },
  metrics: {
    display: 'flex',
    width: '90%',
    maxWidth: '350px',
    margin: '0 auto',
    justifyContent: 'space-between'
  },
  stat: {
    height: '15px',
    paddingTop: '3px',
    color: 'rgb(100,100,100)'
  },
  type: {
    flexBasis: '50%',
  },
  skillHead: {
    display: 'flex',
    width: '90%',
    maxWidth: '350px',
    margin: '0 auto',
    justifyContent: 'space-between'
  },
  statsCont: {
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '5px',
    marginBottom: '10px',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto'
  },
  leadersHead: {
    margin: '15px auto 7px auto',
    borderBottom: '2px solid red',
    width: 'fit-content',
  },
  leader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    margin: '0 auto'
  },
  boardSpace: {
    margin: '8px 0px'
  },
  dataGrid: {
    height: '50vh',
    minHeight: '435px',
    width: '100%',
    margin: '0 auto',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '5px',
  },
  grid: {
    width: '100%',
    border: 'none',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderRadius: '0px 0px 5px 5px',
    height: 'calc(50vh - 35px)',
    minHeight: '400px',
    overflow: 'auto'
  },
  noContest: {
    margin: '0px 10px'
  },
  leaderboardPage: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
    width: '100%',
    padding: '0px 3px'
  },
  accordionCont: {
    maxWidth: '750px',
    margin: '0 auto'
  },
  getPast: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    marginTop: '15px'
  }
}));


// Adds suffix to number
function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}


// Function to get leaderboards for contests user is enrolled in by making a request to the cloudflare worker endpoint
async function getLeaderboard(domain, arr) {
  let results = fetch(`//${domain}/getLeaderboard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({cList: arr})
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ([]);
    });

  return results;
}



export default function Leaderboard({ user, contests, setLoading, domain }) {
  const contestList = Object.keys(contests);
  const [showPast, setShowPast] = useState(false);
  const [msg, setMsg] = useState('');
  const [boards, setBoards] = useState({});
  const [allBoards, setAllBoards] = useState({});
  const [places, setPlaces] = useState({});
  const [skills, setSkills] = useState({});
  const [expandedContest, setExpandedContest] = useState(false);
  const [gridSelection, setGridSelection] = useState('cumulative');  
  const [gridRowData, setGridRowData] = useState([]);
  const [initialContent, setInitialContent] = useState('');
  const [gridColData, setGridColData] = useState([
    { field: 'overallRank',   headerName: 'Rank',              width: 62, hideSortIcons: true,  hide: false },
    { field: 'minRank',       headerName: 'Rank',              width: 62, hideSortIcons: true,  hide: true },
    { field: 'maxRank',       headerName: 'Rank',              width: 62, hideSortIcons: true,  hide: true },
    { field: 'precipRank',    headerName: 'Rank',              width: 62, hideSortIcons: true,  hide: true },
    { field: 'userName',      headerName: 'User Name',         width: 119, hideSortIcons: false,  hide: false },
    { field: 'overallSkill',  headerName: 'Overall Skill',     width: 102, hideSortIcons: true,  hide: false },
    { field: 'minSkill',      headerName: 'Min. Temp. Skill',  width: 122, hideSortIcons: true,  hide: false },
    { field: 'maxSkill',      headerName: 'Max. Temp. Skill',  width: 124, hideSortIcons: true,  hide: false },
    { field: 'precipSkill',   headerName: 'Precip. Skill',     width: 99, hideSortIcons: true,  hide: false },
  ]);
  const [sortModel, setSortModel] = useState([
    {
      field: 'overallRank',
      sort: 'asc'
    }
  ]);
  
  const classes = useStyles();

  let metricNamesObj = {
    'overall': 'Overall',
    'min': 'Min. Temp.',
    'max': 'Max. Temp.',
    'precip': 'Precip.'
  };

  // Gets leaderboards and sets up state on mount
  useEffect(() => {
    getData();    
  }, []);

  // Generates html for leaderboards
  useEffect(() => {
    if (expandedContest) {
      setInitialContent(generateInitialContent(expandedContest));
    }
  }, [expandedContest, sortModel, gridColData, gridRowData]);

  // Sets table data for the currently viewed leaderboard
  useEffect(() => {
    if (expandedContest && boards[expandedContest]) {
      setGridRowData(boards[expandedContest][gridSelection].map((obj, index) => { return {...obj, id: index}; }));
    }
  }, [gridSelection, expandedContest]);

  // Gets leaderboard data
  const getData = () => {
    setLoading(true);
    
    getLeaderboard(domain, contestList)
      .then(results => {
        if (typeof results === 'string') {
          setMsg(results);
        } else {
          const finalDisplayDate = subMonths(new Date(), 1);

          let placesObj = {};
          let skillsObj = {};
          let newBoards = { 'current': {}, 'past': {} };
          contestList.forEach(contestName => {
            try {
              placesObj[contestName] = {};
              skillsObj[contestName] = {};
              let contestObj = results['leaderboards'][contestName];
              let contestInfo = contests[contestName];

              if (isAfter(parseISO(contestInfo.endDate), finalDisplayDate)) {
                newBoards.current[contestName] = contestObj;
              } else {
                newBoards.past[contestName] = contestObj;
              }
  
              Object.keys(contestObj).filter(seg => seg === 'cumulative' || (!(seg < formatISO(subDays(parseISO(contestInfo.beginDate), 5))) && !(seg > contestInfo.endDate))).map(seg => {
                let thisWeek = contestObj[seg].filter(userObj => userObj.userName === user)[0];

                if (thisWeek) {
                  placesObj[contestName][seg] = {
                    overall: thisWeek.overallRank,
                    min: thisWeek.minRank,
                    max: thisWeek.maxRank,
                    precip: thisWeek.precipRank
                  };

                  skillsObj[contestName][seg] = {
                    overall: thisWeek.overallSkill,
                    min: thisWeek.minSkill,
                    max: thisWeek.maxSkill,
                    precip: thisWeek.precipSkill
                  };
                } else {
                  placesObj[contestName][seg] = {
                    overall: undefined,
                    min: undefined,
                    max: undefined,
                    precip: undefined
                  };

                  skillsObj[contestName][seg] = {
                    overall: undefined,
                    min: undefined,
                    max: undefined,
                    precip: undefined
                  };
                }
              });
            } catch (e) {
              console.error(e);
              delete placesObj[contestName];
              delete skillsObj[contestName];
            }
          });

          setPlaces(placesObj);
          setSkills(skillsObj);
          setAllBoards(newBoards);
          setBoards(newBoards[showPast ? 'past':'current']);
        }
      });
      
    setLoading(false);
  };

  const togglePastFuture = () => {
    let newVal = !showPast;
    setShowPast(newVal);
    setBoards(allBoards[newVal ? 'past' : 'current']);
  };

  // Builds a line for cumulative data
  const constructLine = (skill, name, rank) => {
    return (
      <div key={name + skill + rank} className={classes.metrics}>
        <Typography variant='body2' className={classes.stat}>{skill}</Typography>
        <Typography variant='h3' className={classes.type}>{name}</Typography>
        <Typography variant='body2' className={classes.stat}>{rank}</Typography>
      </div>
    );
  };

  // Changes grid data
  const handleGridDataChange = (event) => {
    setGridSelection(event.target.value);
  };

  // Changes which contest is viewed
  const handleContestChange = (panel) => (event, isExpanded) => {
    setExpandedContest(isExpanded ? panel : false);
  };

  // Changes data to match sort preference
  const handleSortChange = (model) => {
    if (model.length !== 0 && (model[0].field !== sortModel[0].field || model[0].sort !== sortModel[0].sort)) {
      
      if (model[0].field !== 'userName') {
        let columns = gridColData.map(col => {
          let hide = true;
          
          if (col.field.includes(model[0].field.slice(0,2)) || col.field.includes('Skill') || col.field === 'userName') {
            hide = false;
          }
  
          return {
            ...col,
            hide
          };
        });
        setGridColData(columns);
      }

      let sortDir = 'desc';
      if (model[0].field.includes('Rank')) {
        sortDir = 'asc';
      } else if (model[0].field === 'userName') {
        sortDir = model[0].sort;
      }

      setSortModel([{ ...model[0], sort: sortDir}]);
    } else if (model.length === 0 && sortModel[0].field === 'userName') {
      let newSort = sortModel[0].sort === 'asc' ? 'desc' : 'asc';
      
      setSortModel([{
        ...sortModel[0],
        sort: newSort
      }]);
    }
  };

  // Function for creating html for leaderboard display
  const generateInitialContent = (contestName) => {
    if (!boards[contestName]) {
      return (
        <div key={'no leaderboard'} className={`${classes.paper} ${classes.noContest}`}>The leaderboard for this contest is unavailable.</div>
      );
    }
      
    let total = boards[contestName]['cumulative'].length;
    let cumuStats = skills[contestName]['cumulative'];

    let weekItems = Object.keys(boards[contestName]).filter(date => (date !== 'cumulative' && boards[contestName][date].length > 0))
      .sort((a,b) => differenceInWeeks(parseISO(a),parseISO(b)));
    weekItems.unshift('cumulative');
    weekItems = weekItems.map((week, index) => <MenuItem key={week} value={week}>{week === 'cumulative' ? 'Cumulative' : `Week ${index}`} Rankings</MenuItem>);

    return (
      <Fragment key={contestName}>
        <div key={contestName} className={classes.statsCont}>
          <Typography variant='h2' className={classes.leadersHead}>Your Cumulative Skill</Typography>

          <div className={classes.skillHead}>
            <Typography>Skill</Typography>
            <Typography>Rank of {total}</Typography>
          </div>
        
          {
            Object.keys(cumuStats).map(metricName => {
              let name = metricNamesObj[metricName];
            
              if (cumuStats[metricName]) {
                let rank = places[contestName]['cumulative'][metricName];
                return constructLine(Math.round(cumuStats[metricName]), name, ordinal_suffix_of(rank));
              } else {
                return constructLine('-', name, '-');
              }
            })
          }

          <div>
            <Typography variant='h2' className={classes.leadersHead}>Cumulative Leaders</Typography>
            {
              Object.keys(cumuStats).map((metricName) => {
                let leadersDivs = [];
                let thisBoard = boards[contestName]['cumulative'];

                let usersObj = thisBoard.filter(u => u[metricName + 'Rank'] <= 5).sort((a,b) => a[metricName + 'Rank'] - b[metricName + 'Rank']);

                for (let i = 0; i < 5; i++) {
                  let name = '-';
                  let skill = '-';

                  if (i < usersObj.length) {
                    name = usersObj[i]['userName'];
                    skill = usersObj[i][metricName + 'Skill'];
                  }

                  leadersDivs.push(<div key={i} className={classes.leader}>
                    <div>{name}</div>
                    <div>{skill}</div>
                  </div>);
                }
              
                return (
                  <div className={classes.boardSpace} key={metricName}>
                    {constructLine('',metricNamesObj[metricName],'')}
                    {leadersDivs}
                  </div>
                );
              })
            }
          </div>
        </div>

        <div className={classes.dataGrid}>
          <Select
            style={{ marginBottom: '3px' }}
            value={gridSelection}
            onChange={handleGridDataChange}
          >
            {weekItems}
          </Select>
          <DataGrid
            className={classes.grid}
            rows={gridRowData}
            columns={gridColData}
            sortModel={sortModel}
            onSortModelChange={handleSortChange}
            rowHeight={40}
            scrollbarSize={0}
            hideFooter
            disableColumnMenu
            disableColumnResize
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className={classes.leaderboardPage}>
      <Typography className={classes.paper} variant='h2'>Leaderboards</Typography>
      {msg && <div className={classes.error}>{msg}</div>}

      <div className={classes.accordionCont}>
        {Object.keys(boards).length > 0 ? 
          Object.keys(boards).map((contestName) => {
            return (
              <Accordion key={contestName} className={expandedContest === contestName ? classes.selected : classes.unselected} expanded={expandedContest === contestName} onChange={handleContestChange(contestName)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <div className={classes.headingCont}>
                    <Typography variant='h2' className={classes.heading}>{contestName}</Typography>
                    <Typography variant='h3' className={classes.secondaryHeading}>{(places[contestName] && places[contestName]['cumulative']['overall'] !== undefined) ? `Overall: ${ordinal_suffix_of(places[contestName]['cumulative']['overall'])}` : (showPast ? 'No cumulative score' : 'Not Yet Ranked')}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  {expandedContest === contestName && initialContent}
                </AccordionDetails>
              </Accordion>
            );
          }) : <div id='no-contests' className={classes.paper}>{showPast ? 'There are no past contests to show you.' : 'You are not currently enrolled in any active contests. Sign up for one to start participating!'}</div>}
      </div>

      <Button className={classes.getPast} onClick={togglePastFuture}>
        {showPast ? 'See Current Contest Leaderboards' : 'See Past Contest Leaderboards'}
      </Button>
    </div>
  );
}

Leaderboard.propTypes = {
  user: PropTypes.string.isRequired,
  contests: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};